import { useEffect, useState } from "react"
import Contact from './Contact.jsx'
import projects from '../projects.json'
import Project from "../Utils/Project.jsx"


export default function Intro(props){

    let [toggleMobile,setToggleMobile] = useState(true)

    let reportWindowSize = () =>{
      if(document.documentElement.clientWidth <= 800){
        setToggleMobile(true)
      }else{
        setToggleMobile(false)
      }
    }
    useEffect(() =>{
        reportWindowSize()
    },[])

    window.addEventListener('resize',  reportWindowSize)
    
    return <>
          <div className="intro noselect" >
            <h1>Hi!</h1>
                <p className="intro-p">
                    My mom calls me <i>Timothy</i>, but <i>Tim</i> or <i>Timo</i> is just fine.
                </p>
                <p className="intro-p">
                I’m a digital designer from Amsterdam, specializing in UX and UI design. I like to think that my background in development and computer science gives me an edge, ensuring that everything we design is not just conceptual but something we can actually bring to life.
                </p>
                    <span className={toggleMobile ? 'displayToggle' : ''}>
                    <p className="intro-p">
                        Below some projects I’ve had the pleasure working on.
                    </p>
                    </span>
                    <div className={toggleMobile ? 'displayToggle' : ''}>    
                        <div className="projects-wrapper">
                        {projects.map((project,index) =>{
                                    return(
                                        <div key={index} onClick={() => props.trigger(project.projectID,project.sideProjectBool,project.sideProjectID)}>
                                            <Project key={index} project={project}/>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>

                <p className="intro-p">
                    <span className={toggleMobile ? '' : 'displayToggle'}> <span className="mobile-view">This is the mobile view. I invite you to look at the desktop version, where I have more space to go into detail about the work I've done.</span> </span>
                </p>
                <Contact />
        </div>
    </>
}   