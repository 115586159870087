export default 
[
    {
        name: 'hot_sunny_avatar', 
        path: 'models/avatar/hot/sunny/avatar.gltf'
    },
    {
        name: 'hot_rainy_avatar', 
        path: 'models/avatar/hot/rainy/avatar.gltf'
    },
    {
        name: 'medium_cloudy_avatar', 
        path: 'models/avatar/medium/cloudy/avatar.gltf'
    },
    {
        name: 'cold_cloudy_avatar', 
        path: 'models/avatar/cold/cloudy/avatar.gltf'
    },
    {
        name: 'cold_rainy_avatar', 
        path: 'models/avatar/cold/rainy/avatar.gltf'
    },
    {
        name: 'medium_rainy_avatar', 
        path: 'models/avatar/medium/rainy/avatar.gltf'
    },
    {
        name: 'medium_sunny_avatar', 
        path: 'models/avatar/medium/sunny/avatar.gltf'
    },
    {
        name: 'freezing_snowy_avatar',
        path: 'models/avatar/freezing/snowy/avatar.gltf'
    },
    {
        name: 'streetsign',
        path: 'models/streetsign/streetsign.gltf'
    },
    {
        name:'nemo',
        path:'models/nemo/Nemo.gltf'
    },
    {
        name:'carrot',
        path:'models/carrot/cartoon_carrot_new.gltf'
    },
    {
        name:'bag',
        path:'models/bag/bag_export.gltf'
    },
    {
        name:'sunny',
        path:'models/weather/sunny/sunny.gltf'
    },
    {
        name:'snowy',
        path:'models/weather/snowy/snowy.gltf'
    },
    {
        name:'cloudy',
        path:'models/weather/cloudy/cloudy.gltf'
    },
    {
        name:'thunder',
        path:'models/weather/thunder/thunder.gltf'
    },
    {
        name:'rainy',
        path:'models/weather/rainy/rainy.gltf'
    },
    {
        name:'night',
        path:'models/weather/night/night.gltf'
    },
    {
        name:'loading',
        path:'images/core/loading/loading_01.gif'
    }
]